var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$form;
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-modal', {
          ref: "upsertModal",
          attrs: {
            "id": "nation-group-modal",
            "size": "xl",
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onUpsertModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
              }, [_vm.isEdit ? [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Update Nation Group")))])] : [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Create New Nation Group")))])], _c('div', {
                staticClass: "ml-5 d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeUpsertModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)], 2)];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Name'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Name'), "*"),
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "name", $$v);
                  },
                  expression: "form.name"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Description')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Description')),
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "description", $$v);
                  },
                  expression: "form.description"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "separator separator-dashed my-5"
        })]), _c('div', {
          staticClass: "col-10"
        }, [_c('div', {
          staticClass: "font-weight-bolder pb-4"
        }, [_vm._v(" " + _vm._s(_vm.$t("List of nations"))), _c('br'), _c('p', {
          staticClass: "font-weight-normal"
        }, [_vm._v(_vm._s(_vm.$t("* Add at least two nations")))])])]), _c('div', {
          staticClass: "col-2 text-right"
        }, [_c('button', {
          staticClass: "btn btn-light-primary font-weight-bolder",
          attrs: {
            "to": {
              name: 'addNations'
            }
          },
          on: {
            "click": _vm.addNations
          }
        }, [_c('i', {
          staticClass: "flaticon2-world mr-2"
        }), _vm._v(_vm._s(_vm.$t("Add nation")) + " ")])]), ((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.nationingroup_set.length) > 0 ? _c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          attrs: {
            "vid": "nationingroup_set"
          }
        }, [_c('b-table', {
          attrs: {
            "items": _vm.form.nationingroup_set,
            "fields": _vm.fields
          },
          scopedSlots: _vm._u([{
            key: "cell(country)",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Nation'),
                  "vid": "nation-".concat(item.country, "-country"),
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref5) {
                    var errors = _ref5.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "options": _vm.getNationSelected,
                        "searchable": "",
                        "disabled": item.country == _vm.nationIso,
                        "error-messages": errors
                      },
                      model: {
                        value: item.country,
                        callback: function callback($$v) {
                          _vm.$set(item, "country", $$v);
                        },
                        expression: "item.country"
                      }
                    })];
                  }
                }], null, true)
              })];
            }
          }, {
            key: "cell(adhesion_date)",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Adhesion date'),
                  "vid": "nation-".concat(item.country, "-adhesion-date")
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref7) {
                    var errors = _ref7.errors;
                    return [_c('date-picker', {
                      attrs: {
                        "error-messages": errors,
                        "placeholder": "YYYY-MM-DD",
                        "overrides": _vm.adhesionDateOverride,
                        "label": ""
                      },
                      model: {
                        value: item.adhesion_date,
                        callback: function callback($$v) {
                          _vm.$set(item, "adhesion_date", $$v);
                        },
                        expression: "item.adhesion_date"
                      }
                    })];
                  }
                }], null, true)
              })];
            }
          }, {
            key: "cell(delete)",
            fn: function fn(row) {
              return [_c('b-button', {
                staticClass: "delete-button mb-7",
                attrs: {
                  "variant": "danger",
                  "disabled": row.item.country == _vm.nationIso
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeItem(row.item);
                  }
                }
              }, [_vm._v(_vm._s(_vm.$t('Delete')))])];
            }
          }], null, true)
        })], 1)], 1) : _vm._e()])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }