<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="nation-group-modal" ref="upsertModal" size="xl" hide-footer @hidden="onUpsertModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Update Nation Group") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create New Nation Group") }}</h4>
          </template>
          <div class="ml-5 d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeUpsertModal">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>
      <div class="form row">
        <div class="col-6">
          <validation-provider v-slot="{ errors }" :name="$t('Name')" rules="required">
            <euro-input v-model="form.name" :label="`${$t('Name')}*`" :error-messages="errors"></euro-input>
          </validation-provider>
        </div>

        <div class="col-6">
          <validation-provider v-slot="{ errors }" :name="$t('Description')">
            <euro-input v-model="form.description" :label="`${$t('Description')}`" :error-messages="errors"></euro-input>
          </validation-provider>
        </div>

        <div class="col-12">
          <div class="separator separator-dashed my-5"></div>
        </div>

        <div class=" col-10">
          <div class="font-weight-bolder pb-4">
            {{ $t("List of nations") }}<br>
            <p class="font-weight-normal">{{ $t("* Add at least two nations") }}</p>
          </div>
        </div>
        <div class="col-2 text-right">
          <button :to="{ name: 'addNations' }" class="btn btn-light-primary font-weight-bolder" @click="addNations">
            <i class="flaticon2-world mr-2"></i>{{ $t("Add nation") }}
          </button>
        </div>
        <div v-if="form?.nationingroup_set.length > 0" class="col-12">
          <validation-provider vid="nationingroup_set">
            <b-table :items="form.nationingroup_set" :fields="fields">
              <template #cell(country)="{ item }">
                <validation-provider v-slot="{ errors }" :name="$t('Nation')" :vid="`nation-${item.country}-country`"
                  rules="required">
                  <euro-select v-model="item.country" :options="getNationSelected" searchable
                    :disabled="item.country == nationIso" :error-messages="errors"></euro-select>
                </validation-provider>
              </template>
              <template #cell(adhesion_date)="{ item }">
                <validation-provider v-slot="{ errors }" :name="$t('Adhesion date')"
                  :vid="`nation-${item.country}-adhesion-date`">
                  <date-picker v-model="item.adhesion_date" :error-messages="errors" placeholder="YYYY-MM-DD"
                    :overrides="adhesionDateOverride" label=""></date-picker>
                </validation-provider>
              </template>
              <template #cell(delete)="row">
                <b-button class="delete-button mb-7" variant="danger" :disabled="row.item.country == nationIso"
                  @click="removeItem(row.item)">{{ $t('Delete')
                  }}</b-button>
              </template>
            </b-table>
          </validation-provider>
        </div>
      </div>
    </b-modal>
  </validation-observer>
</template>
<script>
import { getValidationErrors } from "@/core/helpers";
import Swal from "sweetalert2";
import { backendErrorSwal, validationErrorSwal } from "@/core/helpers/swal";
import NationsGroupsService from "@/core/services/nation/nations-groups.service";
import DatePicker from "@/view/components/datePicker/datePicker.vue";

export default {
  components: {
    DatePicker
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    nationInfo: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      isSaving: false,
      apiErrors: null,
      form: {
        name: "",
        description: "",
        nationingroup_set: []
      },
      fields: [
        { label: this.$t('Nation' + '*'), key: 'country', thClass: ["col-md-4", "justify-content-left"] },
        { label: this.$t('Adhesion date'), key: 'adhesion_date' },
        { label: '', key: 'delete' },
      ],
      addedNations: this.countries,
    }
  },
  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    },
    validationObserver() {
      return `validationObserver-${this._uid}`;
    },
    adhesionDateOverride() {
      return {
        viewMode: 'years',
      }
    },
    getNationSelected() {
      const selectedNations = this.form.nationingroup_set.map(formNation => formNation.country);
      this.countries.filter(country => {
        if (selectedNations.includes(country.value)) {
          country.$isDisabled = true;
        } else {
          country.$isDisabled = false;
        }
      })
      return this.countries;
    },
    nationIso() {
      return this.nationInfo?.iso ? this.nationInfo.iso : ""
    },
  },
  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = getValidationErrors(val, this.form);
        if (newErrors.nationingroup_set && newErrors.nationingroup_set.length) {
          newErrors.nationingroup_set.forEach((nation, id) => {
            const n = this.form.nationingroup_set[id];
            if (!n) return;
            console.log("apiErrors", n, " ", nation)
            if (nation.adhesion_date) {
              newErrors[`nation-${n.nation}-adhesion-date`] = nation.adhesion_date;
            }
          });
        }
        formObs.setErrors({ ...newErrors });
      },
    },
    existingForm: {
      deep: true,
      handler(newVal) {
        this.handleFormInitialization(newVal);
      },
    },
  },
  mounted() {
    this.handleFormInitialization(this.existingForm);
  },
  methods: {
    handleFormInitialization(data) {
      if (Object.keys(data).length > 0) {
        this.form = { ...this.form, ...data };
      } else if (this.nationInfo) {
        this.form = {
          name: "",
          description: "",
          nationingroup_set: [
            { country: null, adhesion_date: null },
            { country: this.nationInfo.iso, adhesion_date: null },
          ],
        };
      } else {
        this.form = {
          name: "",
          description: "",
          nationingroup_set: [
            { country: null, adhesion_date: null },
            { country: null, adhesion_date: null }
          ],
        };
      }
    },
    addNations() {
      const selectedNations = this.getNationSelected;
      if (selectedNations.length > 0) {
        this.form.nationingroup_set.unshift({
          country: null,
          adhesion_date: null,
        });
      }
    },
    removeItem(nation) {
      const index = this.form.nationingroup_set.indexOf(nation);
      if (index !== -1) {
        this.form.nationingroup_set.splice(index, 1);
      }
    },
    closeUpsertModal() {
      this.$bvModal.hide('nation-group-modal');
    },

    onUpsertModalHidden() {
      this.form = null;
      this.isSaving = false;
    },
    async submit() {
      if (this.form.nationingroup_set.length < 2) {
        validationErrorSwal();
        return;
      }
      this.isSaving = true;
      try {
        if (this.isEdit) {
          const form = { ...this.form }
          await NationsGroupsService.update(form.id, form);
        } else {
          await NationsGroupsService.create({ ...this.form });
        }
        this.closeUpsertModal();
        this.$emit('refresh');

        let title = this.$t("Group created")
        if (this.isEdit) {
          title = this.$t("Group Updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        console.log(err);
        if (typeof err == "object") {
          this.apiErrors = err;
          validationErrorSwal();
          return;
        }
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the group. Please try again.")
          if (this.isEdit) {
            message = err?.response?.data?.detail ?? this.$t("Failed to update the group. Please try again.")
          }
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.collapsed {
  & i {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
}

.not-collapsed {
  & i {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
}

.text-small {
  font-size: 0.8rem;
}
</style>