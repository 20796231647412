import api from "../api.service";

class NationsGroupsService {
  async getGroups() {
    const res = await api.get("nations-groups/");
    return res.data;
  }

  async getGroupsPaginated({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    prices = null,
    fields = null,
    expand = null,
    omit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      risk_maps_prices: prices
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }

    const res = await api.get("nations-groups/", {
      params,
    });
    return res.data;
  }

  async create(body) {
    const res = await api.post(`nations-groups/`, body);
    return res.data;
  }

  async update(id, body) {
    const res = await api.patch(`nations-groups/${id}/`, body);
    return res.data;
  }
  async delete(id) {
    const res = await api.delete(`nations-groups/${id}/`);
    return res.data;
  }
}



export default new NationsGroupsService();